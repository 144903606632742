import React from 'react'
import styled from 'styled-components'
import WMKLogoMark from './WMKLogoMark'
//import LogoSVG from '../../assets/WMK-Logo.svg'
import CSS from 'csstype';
 
const Wrap = styled.div`
  &.white {
    svg {
      width: 100vw;
      height: 30vw;
      path {
        fill: rgba(255,255,255,0.1);
      }
    }
    position: absolute;
    z-index: 0;
    right: -45vw;
    top: auto;
    width: 100vw;
    height: 30vw;
  }
`
 
const WMKLogo = ({ white, style }: { white?: boolean, style?: CSS.Properties }) => {
  return (
    <Wrap className={white ? "white" : ""}>
      {/* <LogoSVG /> */}
      <WMKLogoMark />
    </Wrap>
  )
}

export default WMKLogo