import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Theme from "../../../vars/ThemeOptions";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
/* Component Imports */
import Logo from "../../common/Logo";
import Hamburger from "./Hamburger/Hamburger";
import Drawer from "./Hamburger/HamburgerDrawer";
// import StatusBar from "./StatusBar";
// import { HubUserContext, isValidUser } from "../../context/HubspotContext";
//import SubHeading from "../../common/typography/SubHeading";
import BlogLink from "../../routes/Blog/BlogLink";
import { colors } from "../../../vars/palette";
import { WmkLink } from "wmk-link";

const ContainerWrap = styled(Container)`
  position: relative;
  background-color: ${colors.hex("white")};
  z-index: 1000;
  & > .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease;
  }
  &.stuck {
    position: fixed;
    top: 0;
    left: 0;
    & > .row {
      display: flex;
      width: 100%;
      align-items: center;
      transition: all 0.3s ease;
    }
  }
  &.stuck .logo img {
    transition: all 0.3s ease;
  }
  .logo img {
    width: 100%;
    transition: all 0.7s ease;
  }
  .burger-col {
    display: flex;
    justify-content: flex-end;
  }
  .logo-link {
    position: relative;
    left: 25%;
  }
  @media only screen and (max-width: 767px) {
    .logo-link {
      left: 10%;
    }
  }
  .blog-col {
    justify-content: flex-end;
    display: flex;
    right: 15%;
  }
`;

const Header = ({ height, border }: { height: number; border: number }) => {
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  const [isHeaderStuck, setIsHeaderStuck] = useState(false);
  const [scrollPos, setScrollPos] = useState(0);
  const [validUser, setValidUser] = useState(false);

  useEffect(() => {
    if (window && localStorage?.getItem("hasCompletedForm")) {
      setValidUser(true);
    }
  }, []);

  const refHeader = useRef<HTMLDivElement>();

  const headerHeight = isHeaderStuck ? height / 3 - border : height - border;
  const _className = isHeaderStuck ? "stuck" : "";

  const toggleBurgerOpen = () => {
    setIsBurgerOpen(!isBurgerOpen);
  };

  useEffect(() => {
    const scroll = scrollPos;
    if (refHeader?.current) {
      setIsHeaderStuck(scroll >= refHeader.current?.offsetHeight);
    }
  }, [scrollPos]);

  useScrollPosition(({ currPos }) => {
    setScrollPos(-currPos.y);
  });

  return (
    <>
      {/* {true === false && <StatusBar />} */}
      <ContainerWrap
        style={{
          borderBottom: `${border}px solid ${Theme.lighter("body", 90)}`,
        }}
        fluid={true}
        className={_className}
        ref={refHeader}
        id="header"
      >
        <Row
          nogutters={"true"}
          style={{
            height: `${height / 2 - border + 20}px`,
          }}
        >
          <Col xs={6}>
            <WmkLink to={`/`} className="logo-link" label="Watermark Logo">
              <Logo
                style={{
                  maxHeight: isHeaderStuck
                    ? `${height * 0.25}px`
                    : `${height * 0.4}px`,
                }}
              />
            </WmkLink>
          </Col>
          {/* <HubUserContext.Consumer>
            {(data) => {
              const { user } = data ? data : { user: null }; */}
          {validUser ? (
            <Col xs={6} className="burger-col">
              <Hamburger
                height={headerHeight}
                isOpen={isBurgerOpen}
                toggle={toggleBurgerOpen}
                isStuck={isHeaderStuck}
              />
            </Col>
          ) : (
            <Col xs={6} className="blog-col">
              <BlogLink to="/blog">Explore Our Blogs</BlogLink>
            </Col>
          )}
          {/* }}
          </HubUserContext.Consumer> */}
        </Row>
      </ContainerWrap>
      {isHeaderStuck && <div style={{ height: `${height / 2 + border}px` }} />}
      <Drawer
        headerHeight={headerHeight}
        isOpen={isBurgerOpen}
        toggle={toggleBurgerOpen}
      />
    </>
  );
};

export default Header;

Header.defaultProps = {
  height: 90,
  border: 1,
};

Header.propTypes = {
  height: PropTypes.number,
  border: PropTypes.number,
};
