/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { ReactChild, ReactElement } from "react";
import PropTypes from "prop-types";
import { MainLayout, Sticky } from "wmk-layout";
import styled from "styled-components";
import Header from "./Header/Header";

const StyledGlobal = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@500&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap");
  .raj {
    font-family: "Rajdhani", sans-serif;
  }
  .serif {
    font-family: "EB Garamond", serif;
  }
  .wmk-link:has(> .block-title) {
    text-decoration: none;
    transition: all 0.3s ease;
  }
`;

const Layout = ({
  children,
  unlocked,
}: {
  children: ReactElement | ReactChild[];
  unlocked?: undefined;
}) => {
  return (
    <StyledGlobal>
      <MainLayout
        Header={
          <Sticky>
            <Header
              // siteTitle={`ESG Reports | Watermark Agency`}
              // unlocked={unlocked}
            />
          </Sticky>
        }
        Footer={
          <footer>{/* <Copyright>Watermark Agency</Copyright> */}</footer>
        }
      >
        {children}
      </MainLayout>
    </StyledGlobal>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
