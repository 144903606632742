import React from "react";
import Theme from "../../../vars/ThemeOptions";
import styled from "styled-components";
// import PropTypes from 'prop-types'
import { colors } from "../../../vars/palette";

const SubHeadWrap = styled.h2`
  text-transform: uppercase;
  color: ${colors.hex("secondary")};
  font-family: ${Theme.font.head};
  font-weight: 700;
  &.reverse {
    color: ${colors.hex("white")};
  }
`;

const SubHeading = ({ text, reverse }: { text: string; reverse?: boolean }) => {
  const colorClass = reverse ? " reverse" : "";
  return (
    <SubHeadWrap className={"block-title" + colorClass}>{text}</SubHeadWrap>
  );
};

export default SubHeading;

// SubHeading.propTypes = {
//     text: PropTypes.string.isRequired,
//     reverse: PropTypes.bool
// }

// SubHeading.defaultProps = {
//     reverse: false
// }
