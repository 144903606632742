import React from "react";
import styled from "styled-components";
import { colors } from "../../../../vars/palette";

const BurgerWrap = styled.div<{ height: string }>`
  position: relative;
  padding: 0 1rem;
  right: 25%;
  height: 40px;
  width: 40px;
  cursor: pointer;
  z-index: 1000;
  transition: opacity 0.25s ease;
  span {
    background: ${colors.hex("primary")};
    border: none;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.35s ease;
    cursor: pointer;
    &.top {
      transform: rotate(130deg);
      left: -16px;
      top: ${({ height }) => (height ? `${height}` : `0`)};
    }
    &.mid {
      transform: rotate(130deg);
      left: 0;
      top: ${({ height }) => (height ? `${height}` : `0`)};
    }
    &.bot {
      transform: rotate(130deg);
      left: 16px;
      top: ${({ height }) => (height ? `${height}` : `0`)};
    }
  }
  &.open {
    span {
      &.top {
        transform: rotate(45deg);
        left: 0;
      }
      &.mid {
        transform: rotate(180deg);
        left: 0;
        opacity: 0;
      }
      &.bot {
        transform: rotate(-45deg);
        left: 0;
      }
    }
  }
  &.stuck {
    span {
      width: 70%;
      top: 0.75rem;
      &.top {
        left: -12px;
      }
      &.bot {
        left: 12px;
      }
    }
  }
  &.stuck.open {
    span {
      &.top {
        transform: rotate(45deg);
        left: 0;
      }
      &.mid {
        transform: rotate(180deg);
        left: 0;
        opacity: 0;
      }
      &.bot {
        transform: rotate(-45deg);
        left: 0;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    right: 10%;
  }
`;

const BurgerButton = (props: {
  height: number;
  isOpen: boolean;
  toggle: Function;
  isStuck: boolean;
}) => {
  const stateClass = props.isOpen ? "open" : "shut";
  const stuckClass = props.isStuck ? " stuck" : "";

  return (
    <BurgerWrap
      onClick={() => props.toggle()}
      className={stateClass + stuckClass}
      // style={{ top: `${props.height / 2 / 2}px` }}
      height={`${props.height / 2 / 2}px`}
    >
      <span className="top"></span>
      <span className="mid"></span>
      <span className="bot"></span>
    </BurgerWrap>
  );
};

export default BurgerButton;
