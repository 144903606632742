import React from "react";
import SubHeading from "../../common/typography/SubHeading";
import styled from "styled-components";
import { colors } from "../../../vars/palette";
import { WmkLink } from "wmk-link";
import CSS from 'csstype';

const StyledLink = styled(WmkLink)`
  margin: 0;
  padding: 0;
  text-decoration: none;
  .a {
    z-index: 1000;
  }
  .block-title {
    margin: 1vh 0.25vh;
    padding: 0.5vh 1.5vh;
    font-weight: 400;
    font-size: 3.25vh;
    border: 2px solid;
    border-radius: 2vh;
    transition: all 0.4s ease;
  }
  :hover {
    text-decoration: none;
    transition: all 0.4s ease;

    .block-title {
      color: ${colors.hex("white")};
      background-color: ${colors.hex("secondary")};
      transition: all 0.3s ease;
      border-color: ${colors.hex("secondary")};
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
    }
  }
`;

const BlogLink = ({
  to,
  children,
  style,
}: {
  to: string;
  children: string;
  style?: CSS.Properties;
}) => {
  return (
    <StyledLink to={to} style={style}>
      <SubHeading text={children} />
    </StyledLink>
  );
};

export default BlogLink;
