import React from "react";
import styled from "styled-components";
import { IoIosPower } from "react-icons/io";
import { colors, WMKGradient } from "../../vars/palette";
import { WmkLink } from "wmk-link";

const WrapLink = styled(WmkLink)`
  width: 320px;
  min-height: 45px;
  ${WMKGradient.grad()}
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  padding: 5px 75px 5px 25px;
  border-radius: 100px;
  position: relative;
  z-index: 50;
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
  p {
    color: ${colors.hex("white")};
    margin-bottom: 0;
  }
`;

const IconWrap = styled.div`
  padding: 3px 10px 3px 0;
  margin-right: 10px;
  border-right: 1px solid ${colors.hex("white")};
  color: ${colors.hex("white")};
  font-size: 150%;
  svg {
    position: relative;
    bottom: 3px;
  }
`;

const CTAButton = () => {
  return (
    <WrapLink to={`/create-your-esg-report`}>
      <IconWrap>
        {/* <CustomPDFIcon /> */}
        <IoIosPower />
      </IconWrap>
      <p>GET STARTED TODAY</p>
    </WrapLink>
  );
};

export default CTAButton;
