import React from "react";
import Toggle from "./HamburgerButton";

const Hamburger = (props: {
  height: number;
  isOpen: boolean;
  toggle: Function;
  isStuck: boolean;
}) => {
  return (
    <>
      <Toggle
        isOpen={props.isOpen}
        toggle={props.toggle}
        height={props.height}
        isStuck={props.isStuck}
        //unlocked={props.unlocked}
      />
    </>
  );
};

export default Hamburger;
