import React, { useEffect, useState } from "react";
import { getHash } from "../../vars/ThemeOptions";

const WMKLogoMark = () => {
  const [hash, setHash] = useState("default");
  const idLinearGradient = "lg-" + hash;
  const idGroup = "group-" + hash;
  const idPath = "path-" + hash;
  useEffect(() => {
    setHash(getHash());
  }, []);
  return hash !== "default" ? (
    <svg
      width="105.305"
      height="38.052"
      viewBox="0 0 105.305 38.052"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <>
        <use xlinkHref={`${idLinearGradient + "-1"}`} />
        <defs>
          <linearGradient
            id={idLinearGradient + "-1"}
            x1="0.679"
            y1="0.5"
            x2="9.159"
            y2="0.5"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0.046" stopColor="#ca2b2b" />
            <stop offset="0.313" stopColor="#de5917" />
            <stop offset="0.845" stopColor="#e9c922" />
          </linearGradient>
          <linearGradient
            id={idLinearGradient + "-2"}
            x1="-1.864"
            y1="0.5"
            x2="4.218"
            y2="0.5"
            xlinkHref={"#" + idLinearGradient + "-1"}
          />
          <linearGradient
            id={idLinearGradient + "-3"}
            x1="0.157"
            y1="0.5"
            x2="2.663"
            y2="0.5"
            xlinkHref={"#" + idLinearGradient + "-1"}
          />
          <linearGradient
            id={idLinearGradient + "-4"}
            x1="-2.216"
            y1="0.5"
            x2="2.881"
            y2="0.5"
            xlinkHref={"#" + idLinearGradient + "-1"}
          />
        </defs>
        <g
          id={idGroup}
          data-name={idGroup}
          transform="translate(0 0)"
          style={{ isolation: "isolate" }}
        >
          <path
            id={idPath + "-1"}
            data-name={idPath + "-1"}
            d="M344.472,229.126a2.48,2.48,0,0,1-1.847-.823L329.464,213.65a2.482,2.482,0,1,1,3.692-3.317l13.163,14.652a2.483,2.483,0,0,1-1.847,4.141Z"
            transform="translate(-328.828 -191.129)"
            fill={`url(#${idLinearGradient + "-1"})`}
          />
          <path
            id={idPath + "-2"}
            data-name={idPath + "-2"}
            d="M388.112,236a2.535,2.535,0,0,1-2.154-1.193l-20.2-32.3a2.537,2.537,0,1,1,4.3-2.689l20.2,32.3A2.537,2.537,0,0,1,388.112,236Z"
            transform="translate(-305.955 -197.945)"
            fill={`url(#${idLinearGradient + "-2"})`}
          />
          <path
            id={idPath + "-3"}
            data-name={idPath + "-3"}
            d="M364.042,236.093a9.046,9.046,0,0,1-6.9-3.174L331.1,202.658a2.537,2.537,0,1,1,3.845-3.31l26.046,30.262a4.048,4.048,0,0,0,6.432-.382l19.757-29.39a2.536,2.536,0,1,1,4.209,2.829L371.63,232.058a9.054,9.054,0,0,1-7.03,4.019C364.413,236.088,364.227,236.093,364.042,236.093Z"
            transform="translate(-327.792 -198.041)"
            fill={`url(#${idLinearGradient + "-3"})`}
          />
          <path
            id={idPath + "-4"}
            data-name={idPath + "-4"}
            d="M389.319,218.28c-.086,0-.172,0-.26,0A6.3,6.3,0,0,1,384,215.412l-8.549-13.3a2.537,2.537,0,1,1,4.268-2.743l8.549,13.3a1.233,1.233,0,0,0,.995.537,1.256,1.256,0,0,0,1.057-.468L400.652,199.2a2.536,2.536,0,1,1,4.032,3.078l-10.332,13.535A6.323,6.323,0,0,1,389.319,218.28Z"
            transform="translate(-299.901 -198.204)"
            fill={`url(#${idLinearGradient + "-4"})`}
          />
        </g>
      </>
    </svg>
  ) : null;
};

export default WMKLogoMark;
