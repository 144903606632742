import React from "react";
import styled from "styled-components";
import Theme from "../../../../vars/ThemeOptions";
import { Link, useStaticQuery, graphql } from "gatsby";
import CTAButton from "../../../common/CTAButton";
// import {
//   FaFacebookF,
//   FaLinkedinIn,
//   FaTwitter,
//   FaInstagram,
// } from "react-icons/fa";
// import { SocialMediaIcons } from "wmk-lib";
import BlogLink from "../../../routes/Blog/BlogLink";
import { colors } from "../../../../vars/palette";
import { GatsbyImageData, Img, WmkImage } from "wmk-image";

const DrawerWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  right: 0;
  padding: 0;
  z-index: 999;
  // overflow: scroll;
  width: 100%;
  height: 120%;
  transform: translateY(-100rem);
  background: none;
  transition: all 0.3s ease-out;
  & > * {
    // visibility: hidden;
  }
  &.open {
    background: ${colors.rgba("black", 0.8)};
    transform: translateY(10px);
    transition: all 0.3s ease-out;
    & > * {
      visibility: visible;
    }
  }
  .drawer {
    height: 100%;
    overflow: scroll;
    background: ${colors.rgba("black", 0.2)};
    max-width: 35%;
    padding: 50px 5% 0px 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    right: 0;
    z-index: 999;
    border-left: 3px solid ${colors.hex("white")};
    text-decoration: none;
    .blog-link {
      z-index: 1000;
    }
    a.topic-link {
      opacity: 0;
      position: relative;
      z-index: 50;
      width: 85%;
      max-width: 500px;
      opacity: 1;
      left: 0vw;
      padding: 25px 0;
      border-bottom: 1px solid ${colors.hex("silver")};
      font-family: ${Theme.font.head};
      text-align: center;
      color: ${colors.hex("white")};
      text-decoration: none;
      p {
        margin-bottom: 0;
        font-weight: bold;
        font-size: 1.2rem;
        text-decoration: none;
        :hover {
          cursor: pointer;
        }
      }
      :hover {
        text-decoration: none;
        cursor: default;
      }
    }
    a:nth-of-type(2) {
      // first topic link
      // border-top: 1px solid ${colors.hex("codGray")};
    }
    // a:first-of-type {
    //   // cta button
    //   margin: 30px 0;
    // }
    .drawer-bg {
      position: absolute;
      z-index: 0;
      top: 0;
      right: 0;
      width: 100%;
      height: 110%;
      .gatsby-image-wrapper {
        height: 100%;
        width: 100%;
      }
    }
    .drawer-bg-overlay {
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
      width: 100%;
      height: 120%;
      background: ${colors.rgba("text", 0.8)};
    }
    .wmk-social-icon {
      position: relative;
      z-index: 500;
      background: ${colors.hex("white")};
      color: ${colors.hex("text")};
    }
  }
  @media only screen and (max-width: 950px) {
    .drawer {
      max-width: 100%;
      width: 100vw;
    }
  }
`;

// const SocialWrap = styled.div`
//   position: relative;
//   z-index: 50;
//   width: 175px;
//   display: flex;
//   flex-wrap: wrap;
//   margin-top: 1rem;
//   width: 50%;
//   height: 75px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   .wmk-link {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     color: ${colors.hex("white")};
//     font-size: 1.25rem;
//     width: 45px;
//     height: 45px;
//     ${WMKGradient.grad()}
//     border-radius: 100px;
//     margin: 0.5vh 0;
//   }
// `;

interface DrawerData {
  topics: {
    edges: {
      node: {
        title: string;
        slug: string;
      };
    }[];
  };
  socials: {
    edges: {
      node: {
        title: string;
        url: string;
        icon: {
          title: string;
          gatsbyImageData: GatsbyImageData;
        };
      };
    }[];
  };
  bg: {
    gatsbyImageData: GatsbyImageData;
    title: string;
  };
}

const Drawer = (props: {
  headerHeight: number;
  isOpen: boolean;
  toggle: Function;
}) => {
  const menuData: DrawerData = useStaticQuery(query);
  const topics = menuData.topics.edges.map((p) => p.node);
  // const socials = menuData.socials.edges.map(p=>p.node);
  // const { fluid } = menuData.bg ? menuData.bg : null;
  const classes = props.isOpen ? "open" : "";

  // const socialIcons = [ <FaInstagram />, <FaFacebookF />, <FaLinkedinIn />, <FaTwitter /> ]
  const socials = menuData?.socials?.edges.map((e) => {
    return {
      title: e.node.title.toLowerCase(),
      url: e.node.url,
      target: "_blank",
    };
  });
  // this is a hack - there is another image
  // that comes in from the socials query
  // that shouldn't be there. rather than
  // fix the query, I'm just shifting off the first
  // element (with the bad data)
  socials.shift();

  return (
    <DrawerWrap
      id="menu-drawer"
      className={classes}
      style={{ top: props.headerHeight - 35 }}
      onClick={() => props.toggle()}
    >
      <div className="drawer">
        <div style={{ zIndex: 10000, margin: "0.5rem" }}>
          <BlogLink
            to="/blog"
            // className="blog-link"
            style={{
              position: "relative",
              transform: "scale(.75)",
              margin: 0,
            }}
          >
            Blog
          </BlogLink>
        </div>
        <CTAButton />
        {topics.map((topic) => (
          <Link className="topic-link" key={topic.title} to={`/${topic.slug}`}>
            <p>{topic.title}</p>
          </Link>
        ))}
        {/* <SocialWrap>
          <div>
            <SocialMediaIcons
              query={socials}
              platforms={[
                { name: "facebook", Icon: FaFacebookF },
                { name: "twitter", Icon: FaTwitter },
                { name: "linkedin", Icon: FaLinkedinIn },
                { name: "instagram", Icon: FaInstagram },
              ]}
            />
          </div>
        </SocialWrap> */}
        <div className="drawer-bg">
          <WmkImage image={new Img(menuData.bg)} />
          {/* <Img fluid={fluid} alt="coin jar with plant growing out of it" /> */}
        </div>
        <div className="drawer-bg-overlay" />
      </div>
    </DrawerWrap>
  );
};

export default Drawer;

const query = graphql`
  {
    topics: allContentfulTopics(sort: { order: ASC, fields: sortOrder }) {
      edges {
        node {
          title
          slug
        }
      }
    }
    socials: allContentfulSocialMedia(
      filter: { platform: { ne: "LP-BLM: Colorado Gives" } }
    ) {
      edges {
        node {
          title: platform
          url
          icon {
            title
            gatsbyImageData
          }
        }
      }
    }
    bg: contentfulAsset(title: { eq: "Menu Drawer Background" }) {
      gatsbyImageData
      title
    }
  }
`;
